$(document).ready(function(){
    const mainContent = document.querySelector('.main-content');
    const windowHeight = window.innerHeight - 100;
    const elements = document.querySelectorAll('.reveal');
    onScroll();
    mainContent.onscroll = (e)=> {
        onScroll();
    }

    function onScroll(){
        for(let i=0; i<elements.length; i++){
            const elementTop = elements[i].getBoundingClientRect().top;
            if(elementTop < windowHeight){
                elements[i].classList.remove('hideLeft');
                elements[i].classList.remove('hideRight');
            }
        }
    }
});
